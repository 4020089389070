#skill {
    /* background-color: whitesmoke; */
    background-color: var(--lighterblue);
    background-size: cover;
    overflow-x: hidden;   
    /* height: calc(100vh - 100px); */
    /* overflow-y: 'scroll'; */
}
#container {
    justify-content: center;
    text-align: center;
}

img {
    height:50px;
    width:50px;
    margin:2%;
}

@media (max-width: 770) {
    #skill {
        height: calc(100vh - 100px);
    }
}