#experience {
    background-color: var(--lighterblue);
    background-size: cover;
    overflow-x: hidden;   
    justify-content: center;
    text-align: center;
}
#container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
#experienceYear {
    /* justify-content: left;
    text-align: left; */
    width: 150px;
    display: flex;
    flex-direction: column;
}

#yearBtn {
    margin: 2%;
}
#yearBtn.active {
    font-size: 24px;
    padding: 10px 20px;
}

#card-container {
    flex-grow: 1;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* gap: 16px; */
}
#experienceCard {
    border-radius: 15px;
    width: 90%;
    text-align: left;
    padding:5%;
    margin: 5%;

}
