#home {
    background: url('/public/images/headerxD.png') center no-repeat;
    opacity: 0.9;
    background-size: cover !important;
    background-position: center;
    width: 100% !important;
    height: 100vh !important; /* 100% of the viewport height */
    /* position: fixed; */
    overflow:hidden;
    z-index:1000;
    justify-content: center;
    text-align: center;
}
.socialmedia {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    list-style: none;
    display: flex;
    justify-content: space-around;
    font-size: 30px;
}
#name-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
    height:100vh;
    /* font-size: 72px; */
    font-weight: bold;
    color: var(--clickables);
    /* white-space: nowrap; */
    padding: 15%;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);

}
#greet {
    font-size: 30px;
}
#name {
    font-size: 72px;
    color: var(--lightest);
    /* white-space: nowrap; */
}
#title {
    font-size: 30px;
}
#socialcontainer {
    display: flex;
    margin-top: 1.5rem;
    gap: 3.5rem;
}
#socials {
    color: var(--lightest);
    text-decoration: none;
}
#socials:hover {
    color:var(--hoverclicks)
}
/* Iphone SE Screen */
@media only screen and (max-width: 600px){
    #name-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* overflow-x: hidden;  */
    }
    #greet, #title {
        font-size: 20px;
    }
    #name {
        font-size:50px;
        white-space: nowrap;
    }
}
/* Iphone 12 Pro Max */
@media only screen and (min-width: 600px) {
    #name-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    #greet, #title {
        font-size: 20px;
    }
    #name {
        font-size:50px;
        white-space: nowrap;
    }
}
/* Iphone 12 Pro */
@media only screen and (max-width: 390px) and (max-height: 844px){
    #name-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    #greet, #title {
        font-size: 20px;
    }
    #name {
        font-size:50px;
        white-space: nowrap;
    }
}
/* iPhone 12 Mini */
@media only screen and (max-width: 360px) and (max-height: 780px){
    #name-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    #greet, #title {
        font-size: 20px;
    }
    #name {
        font-size:50px;
        white-space: nowrap;
    }
}
/* iPhone 12 */
@media only screen and (max-width: 360px) and (max-height: 780px){
    #name-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    #greet, #title {
        font-size: 20px;
    }
    #name {
        font-size:50px;
        white-space: nowrap;
    }
}
.name {
    color: rgb(255, 255, 255);
    font-size: 100%;
    text-shadow: 2px 2px 8px ;
    display: inline-block;
    text-align: center;
    padding: 20px;
    /* animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both; */
}
.letter {
    display: inline-block;
    animation: bounce 0.5s ease-in-out both;
    transform-origin: center bottom;
}

@keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 0, 0);
    }
  
    40%,
    43% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(0, -30px, 0);
    }
  
    70% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(0, -15px, 0);
    }
}

#downBtn {
    position: absolute;
    justify-content: center;
    text-align: center;
    align-items: center;
    top: 80%;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    font-weight: normal;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    padding: 1% 2%;
}
#downBtn:hover {
    color: rgba(255, 255, 255, 1);
}

@media (max-height: 850px) {
    .head {
        height: calc(100vh - 100px);
    }

}