@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600&display=swap');

* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-ms: 90%;
  --darkblue:rgb(92, 117, 146);
  --lightest: rgb(233, 243, 255);

  --superlightblue:rgb(92, 117, 146, .2);
  --lighterblue:rgb(92, 117, 146, .4);
  --hoverclicks:rgb(207, 227, 241);
  --clickables: rgb(168, 190, 214);
}

html {
  scroll-behavior: smooth;

}

::-webkit-scrollbar {
  display: none;
}

/* LOADING PAGE */
.loader-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: var(--darkblue);
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid var(--lighterblue); /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

section {
  padding: 10% 0;
}
/* Navbar  */
#navbar {
  background-color: var(--lightest);
  color:var(--darkblue);
  width:100%;
  height: 90px;
  justify-content: space-around;
  position: fixed;
  top: -90px;
  z-index: 1;
  transition: top 0.3s;
}
#resumeBtn {
  background-color:var(--darkblue);
  border-radius: 10px;
  padding:1%;
}
#resume {
  color:var(--lightest);
  text-decoration: none;
}
#navbar.visible {
  top:0;
}
#navbar.hidden {
  top: -90px;
}
.navbar > .active {
  font-weight: bold;
}
#showAfter {
  display:none;
}
#about {
  background-color: var(--superlightblue);
}

#projects{
  background-color: var(--superlightblue);
}

/* Click icons and buttons */
#clicks {
  color:var(--clickables);
  text-decoration: none;

}
#clicks:hover {
  color:var(--hoverclicks)
}

#description {
  background-color: var(--darkblue);
  text-decoration: none;

}

#description:hover{
  background-color: var(--lighterblue);
}

/* Footer */
footer {
  background-color: var(--lightest);
  background-size: cover;
  padding:5% 0;
}
/* Iphone SE */
@media only screen and (max-width: 600px){
  #navbar {
    display: flex;
    flex-wrap: wrap;
  }
  #homepage {
    width: 100%;
    text-align: center;
  }
}

/* Iphone 12 */
/* @media only screen and (min-width: 600px){
  #navbar {
    display: flex;
    flex-wrap: wrap;
  }
  #homepage {
    width: 100%;
    text-align: center;
  }
} */
/* Iphone 12 Mini */
/* @media only screen and (max-width: 360px) and (max-height: 780px){
  #navbar {
    display: flex;
    flex-wrap: wrap;
  }
  #homepage {
    width: 100%;
    text-align: center;
  }
} */
/* Iphone 12 Pro */
/* @media only screen and (max-width: 390px) and (max-height: 844px){
  #navbar {
    display: flex;
    flex-wrap: wrap;
  }
  #homepage {
    width: 100%;
    text-align: center;
  }
} */
/* Iphone 12 Pro Max */
/* @media only screen and (max-width: 428px) and (max-height: 926px){
  #navbar {
    display: flex;
    flex-wrap: wrap;
  }
  #homepage {
    width: 100%;
    text-align: center;
  }
} */ 
@media only screen and (max-width: 650px) {
  #showAfter {
    position:relative;
    bottom:250px;
    left: 200px;
  }
}
