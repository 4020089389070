#contact {
    background-color: var(--darkblue);
    margin: auto;
    padding: 5% 0;
    color:var(--clickables);
}

@media (max-width: 770) {
    .contact {
        height: calc(100vh - 100px);
    }
}

p {
    font-size: 12px;
}